import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../templates/blog-post.css"
import "./blog.css"

class BlogPage extends React.Component {
  render() {
    const title = "Blog"
    const { data } = this.props
    const recentPosts = data.recentPosts.edges
    const posts = data.posts.edges

    return (
      <Layout location={this.props.location} title={title}>
        <SEO
          title={title}
          keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]}
        />
        <article className="slim">
          <h1 style={{marginBottom: "1em"}}>Blog</h1>
          <div className="ui grid stackable">
            <div className="row">
              <div className="eleven wide column">
                {posts.map(({ node }) => {
                  const title = node.frontmatter.title || node.fields.slug
                  return (
                    <div key={node.fields.slug} className="blog-excerpt">
                      <h2>
                        <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                          {title}
                        </Link>
                      </h2>
                      <small>{node.frontmatter.date}</small>
                      <p>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: node.frontmatter.description || node.excerpt,
                        }}
                      />
                        <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                          &nbsp;[...]
                        </Link>
                      </p>
                    </div>
                  )
                })}
                <hr />

              </div>
              <div className="five wide column recent-posts">
                <h2>Recent posts</h2>
                <ul>
                  {recentPosts.map( (post) => (
                    <li>
                      <Link to={post.node.fields.slug} rel="prev">
                        {post.node.frontmatter.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </article>
      </Layout>
    )
  }
}

export default BlogPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    posts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    },
    recentPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: 5
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
    }
  }
  `
